import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

import { MatChipsModule } from '@angular/material/chips';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbCardModule, NbCheckboxModule, NbInputModule, NbButtonModule, NbDatepickerModule, NbSelectModule, NbIconModule, NbSearchModule, NbSpinnerModule, NbToastrModule } from '@nebular/theme';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { TableComponent } from './components/table/table.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { HttpClientModule } from '@angular/common/http';
import { FilterComponent } from './components/filter/filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';
import { StoreModule } from '@ngrx/store';

import { ActionReducer } from './store/reducers/audit.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    TableComponent,
    FilterComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    Ng2SmartTableModule,
    NbCardModule,
    NbCheckboxModule,
    NbInputModule,
    NbButtonModule,
    NbDatepickerModule.forRoot(),
    NbDatepickerModule,
    NbSelectModule,
    NbEvaIconsModule,
    NbIconModule,
    NbSearchModule,
    FormsModule,
    ReactiveFormsModule,
    NbSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    MatProgressBarModule,
    MatIconModule,
    StoreModule.forRoot({
      loading: ActionReducer
    }),
    NbToastrModule.forRoot(), 
    StoreDevtoolsModule.instrument({maxAge: 25}),
    NbDateFnsDateModule.forRoot({
      parseOptions: { locale: ptBR },
      formatOptions: { locale: ptBR },
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
