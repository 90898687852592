import { Component, ViewChild } from '@angular/core';
import { AuditService } from '../../services/audit.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuditDetailModel } from '../../models/AuditDetailModel';
import _ from 'lodash'

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TableComponent {
  public dataSource: any;
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  public array: AuditDetailModel[];
  public pageEvent;
  public hideFilter = false;

  details: any;
  results: number;
  isLoading: false;

  columnsToDisplay = ['Ação', 'Categoria', 'Usuário', 'Origem', 'Tipo de Usuário', 'Data'];
  columns = ['action', 'category', 'userName', 'origin', 'userType', 'date'];
  expandedElement: true;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator

  constructor(public service: AuditService) { }

  ngOnInit() {
    this.service.loading$.subscribe((item) => {
      this.dataSource = new MatTableDataSource<AuditDetailModel>(item.data)
      this.dataSource.paginator = this.paginator;
      this.array = item.data
      this.totalSize = item.totalResults
      this.iterator();
    })
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(start, end);
    this.dataSource = part;
  }
}

