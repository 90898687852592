import { Component } from '@angular/core';
import { AuditService } from './services/audit.service';
import { AuditFilterDTO } from './models/AuditFilterDTO';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'frontend';
  filterCustom = new AuditFilterDTO();

  constructor(public service: AuditService) {
    const urlParams = new URLSearchParams(window.location.search);
    this.service.hideFilter = urlParams.get('hide-filter');
  }
}
