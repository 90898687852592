import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuditFilterDTO } from "../models/AuditFilterDTO";
import { Store, select } from '@ngrx/store';
import { toggleLoading, getAll } from '../store/actions/audit.actions';
import { environment } from 'src/environments/environment';
import _ from 'lodash';

import { AuditState } from '../store/reducers/audit.reducer'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class AuditService {
  loading$: Observable<any> = null;
  token: String;
  filter = new AuditFilterDTO();
  hideFilter = '';

  constructor(private http: HttpClient, private store: Store<AuditState>) {
    this.token = this.getCookie('_tngAuditHash');
    this.buildFilter();
    this.loadData()
    this.loading$ = this.store.pipe(select('loading'));
  }

  buildFilter() {
    this.filter = new AuditFilterDTO();
    this.filter.build(new URLSearchParams(window.location.search))
  }

  loadData() {
    const url = environment.API_URL + this.filter.getParams();
    return this.http.get(url, {
      headers: {
        'token': `${this.token}`
      }
    }).subscribe(
      (response: any) => this.addData(response),
      (error: any) => this.toggleLoading(false)
    )
  }

  toggleLoading(value) {
    this.store.dispatch(toggleLoading(value))
  }

  addData(data) {
    this.store.dispatch(getAll(data))
  }

  getCookie(name) {
    let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }
}
