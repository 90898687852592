import { Component, OnInit } from "@angular/core";
import { DescriptionEnum } from "../../enums/DescriptionEnum";
import { UserTypeEnum } from "../../enums/UserTypeEnum";
import { FormGroup, FormControl } from "@angular/forms";
import { AuditFilterDTO } from "../../models/AuditFilterDTO";
import { AuditService } from "../../services/audit.service";
import { ActionEnum } from "src/app/enums/ActionEnum";
import { NbToastrService, NbComponentStatus } from "@nebular/theme";
import * as moment from 'moment';

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.css"]
})
export class FilterComponent implements OnInit {
  categorys = this.getAllKeys(DescriptionEnum);
  userTypes = this.getAllKeys(UserTypeEnum);
  actions = this.getAllKeys(ActionEnum);
  today = new Date();

  filterForm = new FormGroup({
    employeeName: new FormControl(),
    userName: new FormControl(),
    userType: new FormControl(),
    action: new FormControl(),
    category: new FormControl(),
    dateIn: new FormControl(
      moment().startOf('month').toDate()
    ),
    dateOut: new FormControl(new Date())
  });

  constructor(
    private service: AuditService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {}

  getAllKeys(value) {
    return Object.keys(value).sort();
  }

  getDescriptionByKey(key) {
    return DescriptionEnum[key];
  }

  getUserTypeByKey(key) {
    return UserTypeEnum[key];
  }

  getActionByKey(key) {
    return ActionEnum[key];
  }

  validForm(): boolean {
    let dateIn = this.filterForm.get("dateIn").value;
    let dateOut = this.filterForm.get("dateOut").value;

    if (!dateIn || !dateOut) {
      this.showToast("warning",'Datas Inválidas', ' As datas são obrigatórias.');
      return false;
    }

    if (dateIn > dateOut) {
      this.showToast("warning",'Datas Inválidas', "A 'Data início' não pode ser maior que a 'Data fim'.");
      return false;
    }

    let rangeInDays = moment(dateOut).diff(moment(dateIn), 'days');
    if (rangeInDays > 30) {
      this.showToast("warning",'Período Inválido', 'O período máximo para pesquisa é de 30 dias.');
      return false;
    }

    return true;
  }

  search() {
    if (!this.validForm()) {
      return;
    }

    this.service.toggleLoading(true);
    this.service.filter = new AuditFilterDTO(this.filterForm);
    this.service.loadData();
  }

  showToast(status: NbComponentStatus, title: String, message: String) {
    this.toastrService.show(message, title, { status });
  }
}
