import {createReducer, on} from '@ngrx/store'
import { toggleLoading, getAll } from '../actions/audit.actions';
import _ from 'lodash';
import { AuditDetailModel } from '../../models/AuditDetailModel';
import { _initialStateFactory } from '@ngrx/store/src/store_module';

export class AuditState {
  loading: boolean;
  data: [];
  totalResults: number;
}

export const initializeState = (): AuditState => {
  return { loading: false, data: [], totalResults: 0 };
};

const loading = createReducer(
  initializeState(),
  on(toggleLoading, (state, {payload}) => ({
    ...state,
    loading: payload
  })),
  on(getAll, (state, {payload}) => ({
    ...state,
    loading: false,
    data: getData(payload),
    totalResults: getTotalResults(payload)
  }))
)

function getData(payload) {
  return  _.map(payload.data.values, element => {
    return new AuditDetailModel(element)
  })
}

function getTotalResults(payload) {
  return payload.data.results;
}

export function ActionReducer(state: any | undefined, action: any) {
  return loading(state, action);
}