import { createAction } from '@ngrx/store'

export enum ActionTypes {
  GET_ALL = '[GET_ALL] Get all audits',
  CLEAR = '[CLEAR] Clear data',
  TOGGLE_LOADING = '[TOGGLE_LOADING] Toggle Loading'
}

export const toggleLoading = createAction(
  ActionTypes.TOGGLE_LOADING,
  ( payload ) => ({ payload })
)

export const getAll = createAction(
  ActionTypes.GET_ALL,
  ( payload ) => ({ payload })
)

export const clear = createAction(
  ActionTypes.CLEAR
)

