import _ from 'lodash';
import { ActionEnum } from '../enums/ActionEnum';
import { UserTypeEnum } from './../enums/UserTypeEnum';
import { DescriptionEnum } from './../enums/DescriptionEnum';
export class AuditDetailModel {
  action: string;
  employer: number;
  entity: {};
  date: string;
  userName: string;
  origin: string;
  userType: string;
  category: string;
  fields: [];
  description: string;

  constructor(response: any) {
    this.userName = response.user.name;
    this.origin = response.origin;
    this.userType = UserTypeEnum[response.user.type];
    this.date = new Date(response.date).toLocaleString('pt-BR')
    this.description = response.customDescription || response.description;
    this.category = DescriptionEnum[response.entity[0].category];
    this.action = ActionEnum[response.action];
    this.fields = response.fields;
  }
}
