export enum ActionEnum {
    BATCH_APPROVAL = "Aprovação em Lote",
    UPDATE = "Atualização",
    INSERT = "Cadastro",
    DELETE = "Exclusão",
    BATCH_DELETION = "Exclusão em Lote",
    BATCH_LAUNCH = "Lançamento em Lote",
    AUTOMATIC_FILL = "Preenchimento Automático",
    PROCESS = "Processamento",
    BATCH_DISAPPROVAL = "Reprovação em Lote",
    LOGIN = "Login",
}
