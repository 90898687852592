import { FormGroup } from "@angular/forms";
import * as moment from 'moment';

export class AuditFilterDTO {
  employeeName: string;
  userName: string;
  userType: string;
  action: string;
  category: string;
  dateIn = moment().subtract({d : 5}).valueOf();
  dateOut =  new Date().getTime();
  referenceDateIn: number;
  referenceDateOut: number;
  employer: number;
  employee: number;
  from: number;
  size: number;

  constructor(filterForm?: FormGroup) {
    this.from = this.from
    this.size = this.size

    if (filterForm) {
      this.employeeName = filterForm.get("employeeName").value || '';
      this.userName = filterForm.get("userName").value || '';
      this.userType = filterForm.get("userType").value || '';
      this.action = filterForm.get("action").value || '';
      this.category = filterForm.get("category").value || '';
      this.dateIn = filterForm.get("dateIn").value;
      this.dateOut = filterForm.get("dateOut").value;
    }
  }

  build(urlParams){
    this.employee = urlParams.get("employee")
    this.userName = urlParams.get('user-name');
    this.action = urlParams.get('action');
    this.category = urlParams.get('category');
    this.dateIn = Number(urlParams.get('date-in'));
    this.dateOut = Number(urlParams.get('date-out'));
    this.referenceDateIn = Number(urlParams.get('reference-date-in'));
    this.referenceDateOut = Number(urlParams.get('reference-date-out'));
    this.userType = urlParams.get('user-type');
    return this
  }

  getParams() {
    let url = "?";
    if (this.employeeName) {
      url += (url  === "?" ? "employee-name=" : "&employee-name=") + this.employeeName;
    }
    
    if (this.userName) {
      url += (url === "?" ? "user-name=" : "&user-name=") + this.userName;
    }

    if (this.action) {
      url += (url === "?" ? "action=" : "&action=") + this.action;
    }

    if (this.employee) {
      url += (url === "?" ? "employee=" : "&employee=") + this.employee;
    }

    if (this.category) {
      url += (url === "?" ? "category=" : "&category=") + this.category;
    }

    if (this.userType) {
      url += (url === "?" ? "type=" : "&type=") + this.userType;
    }

    if (this.dateIn) {
      url += (url === "?" ? "date-in=" : "&date-in=") + this.dateIn.valueOf();
    }

    if (this.dateOut) {
      url += (url === "?" ? "date-out=" : "&date-out=") + this.dateOut.valueOf();
    }

    if (this.referenceDateIn) {
      url += (url === "?" ? "reference-date-in=" : "&reference-date-in=") + this.referenceDateIn.valueOf();
    }

    if (this.referenceDateOut) {
      url += (url === "?" ? "reference-date-out=" : "&reference-date-out=") + this.referenceDateOut.valueOf();
    }

    if (this.from) {
      url += (url === "?" ? "from=" : "&from=") + this.from;
    }

    if (this.size) {
      url += (url === "?" ? "size=" : "&size=") + this.size;
    }

    return url === "?" ? "": url;
  }
}
